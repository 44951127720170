<template>
  <div class="content-box-padding">
    <div class="sub-title">固定式大气超级站</div>
    <div class="line-text" style="margin-top: 20px">
      VOCs红外成像检漏仪主要用于石油化工行业监测甲烷等VOC气体的泄露，通过设备可快速准确定位甲烷等有机挥发气体泄漏位置为消防、环保部门快速准确定位取证、排查隐患发挥重要作用。
    </div>
    <img
      src="~assets/image/business/蜡笔.png"
      alt="蜡笔"
      class="img-center"
      style="margin-top: 50px"
    />
    <img
      src="~assets/image/business/超级站定位.png"
      alt="超级站定位"
      style="margin: 16px 0"
    />
    <img
      src="~assets/image/business/大气超级站配置.png"
      alt="大气超级站配置"
      class="img-center"
      style="margin: 16px; margin-bottom: 76px"
    />
    <div class="sub-title">移动式大气超级站</div>
    <div class="line-text" style="margin-top: 20px">
      移动式大气监测超级站的建设，集大气综合观测及移动于一身，可实现更广泛区域的环境监测内容，为环境管理部门提供多层次、多渠道的综合服务和技术支撑。
    </div>

    <img
      src="~assets/image/business/超级站性能特点.png"
      alt="超级站性能特点"
      style="margin: 47px auto 0 auto"
    />
    <div class="img-title">性能特点</div>
    <img
      src="~assets\image\business\移动式大气超级战集成图.png"
      alt="超级站性能特点"
      style="margin: 47px auto 20px; auto"
    />
    <div class="img-title" style="margin-bottom: 68px">
      移动式大气监测超级站集成图
    </div>

    <div class="sub-title">化工园区有毒有害气体环境风险预警体系建设方案</div>
    <div class="line-text" style="margin-top: 20px">
      通过“空、地、人”多维立体监测网，构建“点、线、域”三级预警体系；依托化工园区大气监控预警中心信息化平台，实现有毒有害气体实时在线监控、园区环境风险及时预警、污染精准溯源、突发应急事件科学指导；辅助移动走航溯源，实现现场排查取证；针对污染企业精准治污，并持续追踪整改。
    </div>
    <div class="img-box">
      <img
        src="~assets\image\business\化工园区1.png"
        alt="化工园区1"
        style="width: 384px"
      />
      <img
        src="~assets\image\business\化工园区2.png"
        alt="化工园区2"
        style="width: 361px"
      />
    </div>

    <div class="sub-title">空气质量预报、溯源及应急管控评估系统解决方案</div>
    <div class="line-text" style="margin-top: 20px">
      在现有的常规污染物监测网络基础上，配备一台集走航、大气空间（立体与水平空间）污染观测及输送通量观测的移动监测系统，结合多途径溯源解析，精准定位污染源，并对污染管控进行整体评估。移动立体监测系统配备大气颗粒物激光雷达、质子转移反应质谱（PTR-MS），紫外多组分在线监测仪（DV-3000）等目前较为先进的大型观测设备，能够给出不同颗粒物空间分布状态、分布强度及随时间演变规律、区别局地污染与外部输送；能够快速明确区域光化学污染分布，解析臭氧成因；为PM2.5和臭氧的协同管控提供有力依据。
    </div>
    <img
      src="~assets\image\business\走航立体监测系统.png"
      alt="走航立体监测系统"
      style="margin: 20px auto 48px auto"
    />

    <div class="sub-title">城市重点大气污染源立体监测与溯源解决方案</div>
    <div class="line-text" style="margin-top: 20px">
      空气质量预报、溯源及应急管控评估系统集合气象资料同化、污染物数据同化、污染源清单自动优化、业务化运行等多种先进技术手段，综合计算效率和预报准确性，实现未来7-10天空气质量预报。<br />
      城市尺度的空气质量预报，充分考虑地形和城市下垫面的影响，开展气象因子的高精度模拟，结合城市级别污染源排放清单模型细化，能够预报市、区一级别的气象要素和空气质量因子。详细（网格尺度）计算污染来源地区，包括交通、电力、工业、生活源贡献。
    </div>
    <img
      src="~assets\image\business\空气质量预报.png"
      alt="空气质量预报"
      style="margin: 20px auto 48px auto"
    />

    <div class="sub-title">PM2.5和O3协同管控一体化智慧系统解决方案</div>
    <div class="line-text" style="margin-top: 20px">
      全国大气污染治理已经进入攻坚期、深水期，一方面PM2.5的改善幅度不断收窄，另一方面O3污染呈现快速的上升和蔓延态势。如何实现PM2.5和O3的“双减双控”是当前大气污染防治领域的热点和难点。PM2.5和O3协同管控一体化智慧平台“，充分运用大数据分析、数值模拟、GIS等先进技术，构建了精细化协同治理的技术路径，支撑地方政府空气质量的持续改善。<br />
      平台在空气质量常规监测、预报数据的基础上，进一步集成污染源在线监控、源清单、气象、遥感等多源数据，通过构建数据清洗与逻辑校验进行数据质控，建成复合污染数据库，通过多维度精细化溯源支撑打赢蓝天保卫战。
    </div>
    <div class="img-box">
      <img
        src="~assets\image\business\协同一体化1.png"
        alt="化工园区1"
        style="width: 436px; height: 233px"
      />
      <img
        src="~assets\image\business\协同一体化2.png"
        alt="化工园区2"
        style="width: 349px; height: 182px; margin-left: 10px"
      />
    </div>

    <div class="sub-title">大气超级站数据分析决策系统解决方案</div>
    <div class="line-text" style="margin-top: 20px">
      功能定位<br />
      融合天空地立体监测、气象、遥感等多源数据以及大气复合污染多维环境模型算法；<br />
      针对颗粒物组分、光化学成分、污染成因、污染来源等提供专业分析和可视化工具；<br />
      有效支撑大气污染立体感知、时空回溯、精准溯源；<br />
      为大气环境科学研判、污染评估、智慧管控、重大活动保障提供重要支撑。<br />
    </div>
    <img
      src="~assets\image\business\大气超级站数据分析决策系统解决方案.png"
      alt="大气超级站数据分析决策系统解决方案"
      style="margin: 20px auto 48px auto"
    />

    <div class="sub-title">化工园区监控预警系统解决方案</div>
    <div class="line-text" style="margin-top: 20px">
      功能定位<br />
      突破传统监测采样范围有限、响应时间迟缓的桎梏，发展更加智能化的连续性、自动化、高精度监测技术；<br />
      建立覆盖化工园区及周边区域的全方位、立体化、全过程智能监测体系；<br />
      构建应对化工园区突发环境事件的动态化、及时性、智能化移动应急溯源监测系统；<br />
      打造化工园区大气污染时空回溯、立体感知、融合预判的预警溯源系统。<br />
    </div>
    <img
      src="~assets\image\business\化工园区监控预警系统解决方案.png"
      alt="化工园区监控预警系统解决方案"
      style="margin: 20px auto 0 auto"
    />
  </div>
</template>

<style lang="scss" scoped>
.img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 80px auto;
}
</style>
